<template>
  <v-row
    :class="$vuetify.theme.dark ? 'dark-background' : 'background'"
    align="center"
    justify="center"
    style="height: 100%"
    no-gutters
  >
    <v-col style="max-width: 600px">
      <v-card class="pt-10 pb-6 px-12 card-content">
        <v-row no-gutters>
          <v-col cols="12" class="text-center pb-4">
            <v-img
              alt="net2phone Logo"
              class="shrink mr-2"
              contain
              src="https://www.net2phone.com/hs-fs/hubfs/NB%202021/logos/n2p-logo-blue.png?width=432&name=n2p-logo-blue.png"
              transition="scale-transition"
              width="190"
            />
          </v-col>
          <v-col cols="12" class="pt-2 px-12">
            <v-stepper v-model="loginStep" style="box-shadow: none">
              <v-stepper-header v-show="false">
                <v-stepper-step :complete="loginStep == 1" step="1">
                </v-stepper-step>
                <v-stepper-step :complete="loginStep == 2" step="2">
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-form ref="login" lazy-validation>
                    <v-text-field
                      data-cy="userlogin"
                      prepend-icon="mdi-account"
                      :rules="[rules.required]"
                      :label="$i18n.t('LOGIN.usernameLabel')"
                      filled
                      rounded
                      dense
                      v-model="username"
                      @keyup.enter="doLogin()"
                    />
                    <v-text-field
                      data-cy="passlogin"
                      prepend-icon="mdi-lock"
                      :label="$i18n.t('LOGIN.passwordLabel')"
                      :rules="[rules.required]"
                      v-model="password"
                      filled
                      rounded
                      dense
                      type="password"
                      @keyup.enter="doLogin()"
                    />
                  </v-form>
                  <v-row no-gutters justify="end">
                    <v-col cols="4" class="pt-6">
                      <v-btn
                        data-cy="loginbtn"
                        :loading="loadingButton"
                        block
                        large
                        class="card-content__footer"
                        dark
                        depressed
                        @click.prevent="doLogin"
                      >
                        {{ $i18n.t("LOGIN.signIn") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content step="2">
                  {{ $i18n.t("LOGIN.emailSentAddress") }}
                  <b>{{ this.maskedEmail }}</b
                  >. <br />{{ $i18n.t("LOGIN.confirmCode") }}
                  <v-otp-input
                    length="6"
                    type="number"
                    v-model="otp"
                  ></v-otp-input>
                  <v-btn
                    :loading="loadingResend"
                    block
                    small
                    depressed
                    dark
                    class="card-content__resend"
                    @click.prevent="resendCode"
                  >
                    {{ $i18n.t("LOGIN.resend") }}
                  </v-btn>
                  <v-row no-gutters justify="end">
                    <v-col cols="4" class="mr-6 pt-6">
                      <v-btn
                        block
                        large
                        class="card-content__footer_back"
                        dark
                        depressed
                        @click.prevent="cancelCode"
                      >
                        {{ $i18n.t("GENERAL.cancel") }}
                      </v-btn>
                    </v-col>
                    <v-col cols="4" class="pt-6">
                      <v-btn
                        block
                        :loading="loadingConfirm"
                        class="card-content__footer"
                        large
                        dark
                        depressed
                        @click.prevent="doConfirmCode"
                      >
                        {{ $i18n.t("GENERAL.confirm") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import LoginModel from "./../models/login.js";

export default {
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const username = urlParams.get("u");
    const password = urlParams.get("p");
    this.username = username || "";
    this.password = password || "";
    this.loginStep = 1;
  },

  data() {
    return {
      username: "",
      password: "",
      showForgetPasswordModal: false,
      loadingButton: false,
      loadingResend: false,
      loadingConfirm: false,
      loginStep: 1,
      maskedEmail: "",
      otp: "",

      forgetEmail: "",
      forgetUsername: "",
      loadingForgetButton: false,

      rules: {
        required: (value) => !!value || "Required",
      },
    };
  },

  methods: {
    cancelCode() {
      this.loginStep = 1;
      this.otp = "";
    },

    async doLogin() {
      if (this.$refs.login.validate()) {
        try {
          const lang = this.$store.state.lang ? this.$store.state.lang : "en";
          this.loadingButton = true;
          const maskedEmail = await LoginModel.login(
            this.username,
            this.password,
            lang
          );
          this.loginStep = 2;
          this.password = "";
          this.maskedEmail = maskedEmail;
        } catch (error) {
          let textError = this.$i18n.t("GENERAL.ERRORLOGIN");
          if (error.message.includes("permission-denied")) {
            textError = this.$i18n.t("GENERAL.ERRORLOGINNOEMAIL");
          } else if (error.message.includes("unknown")) {
            textError = this.$i18n.t("GENERAL.ERRORLOCKEDUSER");
          }
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "",
            text: textError,
          });
        }
        this.loadingButton = false;
      }
    },

    async resendCode() {
      try {
        const lang = this.$store.state.lang ? this.$store.state.lang : "en";
        this.loadingResend = true;
        await LoginModel.resendCode(this.username, lang);
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "success",
          title: "",
          text: this.$i18n.t("LOGIN.resendConfirm"),
        });
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "",
          text: this.$i18n.t("LOGIN.resendError"),
        });
      }
      this.loadingResend = false;
    },

    async doConfirmCode() {
      try {
        this.loadingConfirm = true;
        await LoginModel.confirmCode(this.username, this.otp);
      } catch (error) {
        this.otp = "";
        let textError = this.$i18n.t("LOGIN.invalidCode");
        if (error.message.includes("permission-denied")) {
          textError = this.$i18n.t("LOGIN.expiredCode");
          this.loginStep = 1;
        } else if (error.message.includes("unknown")) {
          textError = this.$i18n.t("LOGIN.triesExceeded");
          this.loginStep = 1;
        }
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "",
          text: textError,
        });
      }
      this.loadingConfirm = false;
    },
  },
};
</script>

<style scoped lang="scss">
.background {
  background-image: url("../assets/background.svg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: var(--v-primary-base);
}

.dark-background {
  background-image: url("../assets/background.svg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #272727;
}

.card-content {
  box-shadow: 10px 10px 43px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border-top: 5px solid #d1404a;
  .card-content__footer {
    background-color: #d1404a !important;
    border-radius: 8px;
  }
  .card-content__footer_back {
    background-color: #b4b4b4 !important;
    border-radius: 8px;
  }
  .card-content__resend {
    background-color: #0095ff !important;
    border-radius: 8px;
  }
}

.password-reset__content {
  box-shadow: 10px 10px 43px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  width: 450px;
}
</style>
